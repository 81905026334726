import axios from "axios";

const apikey = process.env.REACT_APP_HABI_360_API_KEY;
const rootUrl = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_BASE_PATH;
const legalizationDevUrl = process.env.REACT_APP_LEGALIZATION_DEV_BASE_URL;
const legalizationDevKey = process.env.REACT_APP_LEGALIZATION_DEV_API_KEY;
const legalizationProdUrl = process.env.REACT_APP_LEGALIZATION_PROD_BASE_URL;
const legalizationProdKey = process.env.REACT_APP_LEGALIZATION_PROD_API_KEY;
const env = process.env.REACT_APP_ENV;

let legalizationUrl = "";
let legalizationKey = "";
if (env === "development") {
  legalizationUrl = legalizationDevUrl;
  legalizationKey = legalizationDevKey;
}
if (env === "production") {
  legalizationUrl = legalizationProdUrl;
  legalizationKey = legalizationProdKey;
}

export default axios.create({
  baseURL: rootUrl,
  responseType: "json",
  headers: {
    "x-api-key": apikey,
    "Content-Type": "application/json",
  },
});

export const legalizationClient = axios.create({
  baseURL: legalizationUrl,
  responseType: "json",
  headers: {
    "x-api-key": legalizationKey,
    "Content-Type": "application/json",
  },
});

