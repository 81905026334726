import { FormData } from "./models/affidavitSlice.model";

const countries = [
  { label: "Afghanistan", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Alemania", value: "DE" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Antigua y Barbuda", value: "AG" },
  { label: "Arabia Saudita", value: "SA" },
  { label: "Argelia", value: "DZ" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaiyán", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bangladés", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Baréin", value: "BH" },
  { label: "Bélgica", value: "BE" },
  { label: "Belice", value: "BZ" },
  { label: "Benín", value: "BJ" },
  { label: "Bielorrusia", value: "BY" },
  { label: "Birmania", value: "MM" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia y Herzegovina", value: "BA" },
  { label: "Botsuana", value: "BW" },
  { label: "Brasil", value: "BR" },
  { label: "Brunéi", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Bután", value: "BT" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Camboya", value: "KH" },
  { label: "Camerún", value: "CM" },
  { label: "Canadá", value: "CA" },
  { label: "Catar", value: "QA" },
  { label: "Chad", value: "TD" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Chipre", value: "CY" },
  { label: "Ciudad del Vaticano", value: "VA" },
  { label: "Colombia", value: "CO" },
  { label: "Comoras", value: "KM" },
  { label: "Corea del Norte", value: "KP" },
  { label: "Corea del Sur", value: "KP" },
  { label: "Costa de Marfil", value: "CI" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croacia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Dinamarca", value: "DK" },
  { label: "Dominica", value: "DM" },
  { label: "Ecuador", value: "EC" },
  { label: "Egipto", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Emiratos Árabes Unidos", value: "AE" },
  { label: "Eritrea", value: "ER" },
  { label: "Eslovaquia", value: "SK" },
  { label: "Eslovenia", value: "SI" },
  { label: "España", value: "ES" },
  { label: "Estados Unidos", value: "US" },
  { label: "Estonia", value: "EE" },
  { label: "Etiopía", value: "ET" },
  { label: "Filipinas", value: "PH" },
  { label: "Finlandia", value: "FI" },
  { label: "Fiyi", value: "FJ" },
  { label: "Francia", value: "FR" },
  { label: "Gabón", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Ghana", value: "GH" },
  { label: "Granada", value: "GD" },
  { label: "Grecia", value: "GR" },
  { label: "Guatemala", value: "GT" },
  { label: "Guyana", value: "GY" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea ecuatorial", value: "GQ" },
  { label: "Guinea-Bisáu", value: "GW" },
  { label: "Haití", value: "HT" },
  { label: "Honduras", value: "HN" },
  { label: "Hungría", value: "HU" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Irán", value: "IR" },
  { label: "Irak", value: "IQ" },
  { label: "Irlanda", value: "IE" },
  { label: "Islandia", value: "IS" },
  { label: "Islas Marshall", value: "MH" },
  { label: "Islas Salomón", value: "SB" },
  { label: "Israel", value: "IL" },
  { label: "Italia", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japón", value: "JP" },
  { label: "Jordania", value: "JO" },
  { label: "Kazajistán", value: "KZ" },
  { label: "Kenia", value: "KE" },
  { label: "Kirguistán", value: "KG" },
  { label: "Kiribati", value: "KI" },
  { label: "Kuwait", value: "KW" },
  { label: "Laos", value: "LA" },
  { label: "Lesoto", value: "LS" },
  { label: "Letonia", value: "LV" },
  { label: "Líbano", value: "LB" },
  { label: "Liberia", value: "LR" },
  { label: "Libia", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lituania", value: "LT" },
  { label: "Luxemburgo", value: "LU" },
  { label: "Macedonia del Norte", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malasia", value: "MY" },
  { label: "Malaui", value: "MW" },
  { label: "Maldivas", value: "MV" },
  { label: "Malí", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marruecos", value: "MA" },
  { label: "Mauricio", value: "MU" },
  { label: "Mauritania", value: "MR" },
  { label: "México", value: "MX" },
  { label: "Micronesia", value: "FM" },
  { label: "Moldavia", value: "MD" },
  { label: "Mónaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Mozambique", value: "MZ" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Nicaragua", value: "NI" },
  { label: "Níger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Noruega", value: "NO" },
  { label: "Nueva Zelanda", value: "NZ" },
  { label: "Omán", value: "OM" },
  { label: "Países Bajos", value: "NL" },
  { label: "Pakistán", value: "PK" },
  { label: "Palaos", value: "PW" },
  { label: "Panamá", value: "PA" },
  { label: "Papúa Nueva Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Perú", value: "PE" },
  { label: "Polonia", value: "PL" },
  { label: "Portugal", value: "PT" },
  {
    label: "Reino Unido",
    value: "GB",
  },
  { label: "República Centroafricana", value: "CF" },
  { label: "República Checa", value: "CZ" },
  { label: "República del Congo", value: "RDC" },
  { label: "República Democrática del Congo", value: "COD" },
  { label: "República Dominicana", value: "DO" },
  { label: "Ruanda", value: "RW" },
  { label: "Rumanía", value: "RO" },
  { label: "Rusia", value: "RU" },
  { label: "Samoa", value: "WS" },
  { label: "San Cristóbal y Nieves", value: "SKN" },
  { label: "San Marino", value: "SM" },
  { label: "San Vicente y las Granadinas", value: "VC" },
  { label: "Santa Lucía", value: "LC" },
  { label: "Santo Tomé y Príncipe", value: "ST" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leona", value: "SL" },
  { label: "Singapur", value: "SG" },
  { label: "Siria", value: "SY" },
  { label: "Somalia", value: "SO" },
  { label: "Sri Lanka", value: "LK" },
  { label: "Suazilandia", value: "SZ" },
  { label: "Sudáfrica", value: "ZA" },
  { label: "Sudán", value: "SD" },
  { label: "Sudán del Sur", value: "SS" },
  { label: "Suecia", value: "SWE" },
  { label: "Suiza", value: "SUI" },
  { label: "Surinam", value: "SR" },
  { label: "Tailandia", value: "TH" },
  { label: "Tanzania", value: "TZ" },
  { label: "Tayikistán", value: "TJ" },
  { label: "Timor Oriental", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad y Tobago", value: "TT" },
  { label: "Túnez", value: "TN" },
  { label: "Turkmenistán", value: "TM" },
  { label: "Turquía", value: "TR" },
  { label: "Tuvalu", value: "TV" },
  { label: "Ucrania", value: "UA" },
  { label: "Uganda", value: "UG" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistán", value: "UZ" },
  { label: "Vanuatu", value: "CH" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN" },
  { label: "Yemen", value: "YE" },
  { label: "Yibuti", value: "DJ" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabue", value: "ZW" },
];

const civilStates = [
  {
    value: `Casado con sociedad conyugal vigente`,
    label: "Casado con sociedad conyugal vigente",
  },
  {
    value: `Casado sin sociedad conyugal vigente`,
    label: "Casado sin sociedad conyugal vigente",
  },
  {
    value: `Soltero con unión marital de hecho con sociedad patrimonial`,
    label:
      "Soltero con unión marital de hecho con sociedad patrimonial",
  },
  {
    value: `Soltero con unión marital de hecho sin sociedad patrimonial`,
    label:
      "Soltero con unión marital de hecho sin sociedad patrimonial",
  },
  {
    value: `Soltero, sin unión marital de hecho`,
    label: "Soltero sin unión marital de hecho",
  },
];

const booleanOptions = [
  {
    value: `yes`,
    label: "Si",
  },
  {
    value: `no`,
    label: "No",
  },
]

const civilStateObject: any = {
  title: "Estado civil",
  subTitle: "Los estados civiles en Colombia son los siguientes:",
  state: [
    {
      order: "h",
      type: "radio_btn",
      options: civilStates,
      name: "civil_state",
      helper: {
        link: "",
        label: "¿Qué es esto?",
        tooltip: false,
        modal: true,
      },
      label: "Selecciona el estado civil al que perteneces",
      variant: "square",
    },
  ],
};

const citiesOptions = [
  { value: "1", label: "Bogotá" },
  { value: "2", label: "Soacha" },
  { value: "3", label: "Mosquera" },
  { value: "4", label: "Funza" },
  { value: "5", label: "Madrid" },
  { value: "6", label: "Chía" },
  { value: "7", label: "Cajicá" },
  { value: "8", label: "Cota" },
  { value: "9", label: "La Calera" },
  { value: "10", label: "Tenjo" },
  { value: "11", label: "Tabio" },
  { value: "12", label: "Sibaté" },
  { value: "13", label: "Zipaquirá" },
  { value: "14", label: "Facatativá" },
  { value: "15", label: "Bojacá" },
  { value: "16", label: "Gachancipá" },
  { value: "17", label: "Tocancipá" },
  { value: "18", label: "Sopó" },
  { value: "19", label: "Barranquilla" },
  { value: "20", label: "Cali" },
  { value: "21", label: "Abejorral" },
  { value: "22", label: "Abriaquí" },
  { value: "23", label: "Alejandría" },
  { value: "24", label: "Amagá" },
  { value: "25", label: "Amalfi" },
  { value: "26", label: "Andes" },
  { value: "27", label: "Angelópolis" },
  { value: "28", label: "Angostura" },
  { value: "29", label: "Anorí" },
  { value: "30", label: "Anzá" },
  { value: "31", label: "Apartadó" },
  { value: "32", label: "Arboletes" },
  { value: "33", label: "Argelia" },
  { value: "34", label: "Barbosa" },
  { value: "35", label: "Bello" },
  { value: "36", label: "Belmira" },
  { value: "37", label: "Betania" },
  { value: "38", label: "Betulia" },
  { value: "39", label: "Briceño" },
  { value: "40", label: "Buriticá" },
  { value: "41", label: "Cáceres" },
  { value: "42", label: "Caicedo" },
  { value: "43", label: "Caldas" },
  { value: "44", label: "Campamento" },
  { value: "45", label: "Cañasgordas" },
  { value: "46", label: "Caracolí" },
  { value: "47", label: "Caramanta" },
  { value: "48", label: "Carepa" },
  { value: "49", label: "Carolina del Príncipe" },
  { value: "50", label: "Caucasia" },
  { value: "51", label: "Chigorodó" },
  { value: "52", label: "Cisneros" },
  { value: "53", label: "Ciudad Bolívar" },
  { value: "54", label: "Cocorná" },
  { value: "55", label: "Concepción" },
  { value: "56", label: "Concordia" },
  { value: "57", label: "Copacabana" },
  { value: "58", label: "Dabeiba" },
  { value: "59", label: "Donmatías" },
  { value: "60", label: "Ebéjico" },
  { value: "61", label: "El Bagre" },
  { value: "62", label: "El Carmen de Viboral" },
  { value: "63", label: "El Peñol" },
  { value: "64", label: "El Retiro" },
  { value: "65", label: "El Santuario" },
  { value: "66", label: "Entrerríos" },
  { value: "67", label: "Envigado" },
  { value: "68", label: "Fredonia" },
  { value: "69", label: "Frontino" },
  { value: "70", label: "Giraldo" },
  { value: "71", label: "Girardota" },
  { value: "72", label: "Gómez Plata" },
  { value: "73", label: "Granada" },
  { value: "74", label: "Guadalupe" },
  { value: "75", label: "Guarne" },
  { value: "76", label: "Guatapé" },
  { value: "77", label: "Heliconia" },
  { value: "78", label: "Hispania" },
  { value: "79", label: "Itagüí" },
  { value: "80", label: "Ituango" },
  { value: "81", label: "Jardín" },
  { value: "82", label: "Jericó" },
  { value: "83", label: "La Ceja" },
  { value: "84", label: "La Estrella" },
  { value: "85", label: "La Pintada" },
  { value: "86", label: "La Unión" },
  { value: "87", label: "Liborina" },
  { value: "88", label: "Maceo" },
  { value: "89", label: "Marinilla" },
  { value: "90", label: "Medellín" },
  { value: "91", label: "Montebello" },
  { value: "92", label: "Murindó" },
  { value: "93", label: "Mutatá" },
  { value: "94", label: "Nariño" },
  { value: "95", label: "Nechí" },
  { value: "96", label: "Necoclí" },
  { value: "97", label: "Olaya" },
  { value: "98", label: "Peque" },
  { value: "99", label: "Pueblorrico" },
  { value: "100", label: "Puerto Berrío" },
  { value: "101", label: "Puerto Nare" },
  { value: "102", label: "Puerto Triunfo" },
  { value: "103", label: "Remedios" },
  { value: "104", label: "Rionegro" },
  { value: "105", label: "Sabanalarga" },
  { value: "106", label: "Sabaneta" },
  { value: "107", label: "Salgar" },
  { value: "108", label: "San Andrés de Cuerquia" },
  { value: "109", label: "San Carlos" },
  { value: "110", label: "San Francisco" },
  { value: "111", label: "San Jerónimo" },
  { value: "112", label: "San José de la Montaña" },
  { value: "113", label: "San Juan de Urabá" },
  { value: "114", label: "San Luis" },
  { value: "115", label: "San Pedro de los Milagros" },
  { value: "116", label: "San Pedro de Urabá" },
  { value: "117", label: "San Rafael" },
  { value: "118", label: "San Roque" },
  { value: "119", label: "San Vicente" },
  { value: "120", label: "Santa Bárbara" },
  { value: "121", label: "Santa Fe de Antioquia" },
  { value: "122", label: "Santa Rosa de Osos" },
  { value: "123", label: "Santo Domingo" },
  { value: "124", label: "Segovia" },
  { value: "125", label: "Sonsón" },
  { value: "126", label: "Sopetrán" },
  { value: "127", label: "Támesis" },
  { value: "128", label: "Tarazá" },
  { value: "129", label: "Tarso" },
  { value: "130", label: "Titiribí" },
  { value: "131", label: "Toledo" },
  { value: "132", label: "Turbo" },
  { value: "133", label: "Uramita" },
  { value: "134", label: "Urrao" },
  { value: "135", label: "Valdivia" },
  { value: "136", label: "Valparaíso" },
  { value: "138", label: "Vegachí" },
  { value: "139", label: "Venecia" },
  { value: "140", label: "Vigía del Fuerte" },
  { value: "141", label: "Yalí" },
  { value: "142", label: "Yarumal" },
  { value: "143", label: "Yolombó" },
  { value: "144", label: "Yondó" },
  { value: "145", label: "Zaragoza" },
  { value: "146", label: "Pereira" },
  { value: "147", label: "Armenia" },
  { value: "148", label: "Bucaramanga" },
  { value: "148", label: "Yumbo" },
  { value: "150", label: "Jamundí" },
  { value: "151", label: "Cartagena" },
  { value: "152", label: "Villeta" },
  { value: "153", label: "Bucaramanga" },
  { value: "154", label: "Floridablanca" }
];

const getCityObject = (name: string) => {
  const object = {
    type: "dropdown",
    options: citiesOptions,
    value: "",
    name,
    label: "¿En qué ciudad vives?",
    placeholder: "Selecciona tu Ciudad",
  };

  return object;
};

export const formState: FormData = {
  contact: {
    title: "Información de contacto",
    state: [
      {
        type: "dropdown",
        options: countries,
        name: "country",
        label: "¿Cuál es tu país de residencia?",
        placeholder: "Selecciona o escribe tu país",
      },
      {
        type: "input",
        value: "",
        name: "city",
        label: "¿En qué ciudad vives?",
        placeholder: "Selecciona tu Ciudad",
      },
      getCityObject("city"),
      {
        type: "input",
        name: "address",
        label: "¿En qué dirección vives?",
        placeholder: "Ingresa tu dirección",
        value: "",
      },
    ],
  },
  civil_state: civilStateObject,
  childs: {
    title: "Sobre los hijos",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "children",
        label: "¿Tienes hijos actualmente?",
        variant: "rounded",
        sizeComponent: "102px",
        componentsChilds: [
          {
            order: "v",
            type: "radio_btn",
            options: booleanOptions,
            name: "adult_children",
            label: "¿Al día de hoy ¿Alguno de tus hijos es menor de edad?",
            variant: "rounded",
            sizeComponent: "102px",
          },
        ],
      },
    ],
  },
  about_property: {
    title: "Sobre el inmueble",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "about_property",
        label: "¿Tu inmueble está en arriendo en este momento?",
        variant: "rounded",
        sizeComponent: "140px",
        componentsChilds: [
          {
            order: "v",
            type: "date_picker",
            value: new Date().toISOString(),
            options: booleanOptions,
            name: "about_property_date",
            label: "¿Hasta que fecha estará en arriendo el inmueble?",
            subLabel:
              "Esta fecha es muy importante para el proceso, trata de ser lo más preciso posible.",
            variant: "rounded",
            placeholder: "DD/MM/AAAA",
          },
        ],
      },
    ],
  },
  structural_failures: {
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "structural_failures",
        label:
          "¿En algún momento has sido notificado de que tu inmueble tiene o tuvo fallas estructurales, arquitectónicas o de construcción que afecten la estabilidad de (los) inmueble(s), edificio, torre, interior o conjunto?",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          content:
            "Se refiere a una deficiencia fundamental en la construcción o diseño de la edificación, que afecta negativamente la habitabilidad del inmueble y la calidad de vida de los residentes. Estos problemas pueden ser de naturaleza física, como la presencia de grietas en las estructuras, problemas de cimientos e incluso inclinaciones notorias.",
        },
        variant: "rounded",
      },
    ],
  },
  price_minimal_inmobi: {
    title: "Condiciones habinmobiliaria",
    backgroundColor: true,
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "price_minimal_inmobi",
        label:
          "¿Ha sido informado del precio mínimo establecido para venta con habi inmobiliaria de $ 100.000.000 ? ",
        variant: "rounded",
      },
    ],
  },
};

export const formStateLegalization: any = {
  contact_legal: {
    title: "Información de contacto",
    state: [
      {
        type: "dropdown",
        options: countries,
        name: "residence_country",
        label: "¿País de residencia?",
        placeholder: "Selecciona un país",
      },
      {
        type: "input",
        value: "",
        name: "residence_city",
        label: "¿Ciudad donde vives?",
        placeholder: "Selecciona una ciudad",
      },
      getCityObject("residence_city"),
      {
        type: "input",
        name: "residence_address",
        label: "¿Dirección donde vives?",
        placeholder: "Ingresa una dirección",
        value: "",
      },
    ],
  },
  civil_state_legal: civilStateObject,
  childs_legal: {
    title: "Sobre los hijos",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "has_minor_child",
        label: "¿Tienes hijos menores de edad?",
        variant: "rounded",
        sizeComponent: "105px",
        componentsChilds: [
          {
            order: "v",
            type: "radio_btn",
            options: booleanOptions,
            name: "parents_agree",
            label: "¿Está de acuerdo con levantar el patrimonio de familia?",
            variant: "rounded",
            sizeComponent: "105px",
          },
        ],
      },
    ],
  },
  about_property_legal: {
    title: "Sobre el inmueble",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "property_status",
        label: "¿Tu inmueble está en arriendo en este momento?",
        variant: "rounded",
        sizeComponent: "140px",
        componentsChilds: [
          {
            order: "v",
            type: "date_picker",
            value: new Date().toISOString(),
            options: booleanOptions,
            name: "eviction_date",
            label: "¿Hasta que fecha estará en arriendo el inmueble?",
            subLabel:
              "Esta fecha es muy importante para el proceso, trata de ser lo más preciso posible.",
            variant: "rounded",
            placeholder: "DD/MM/AAAA",
          },
        ],
      },
    ],
  },
  structural_failures_legal: {
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: booleanOptions,
        name: "structural_damage_informed",
        label:
          "¿En algún momento has sido notificado de que tu inmueble tiene o tuvo fallas estructurales, arquitectónicas o de construcción que afecten la estabilidad de (los) inmueble(s), edificio, torre, interior o conjunto?",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          content:
            "Se refiere a una deficiencia fundamental en la construcción o diseño de la edificación, que afecta negativamente la habitabilidad del inmueble y la calidad de vida de los residentes. Estos problemas pueden ser de naturaleza física, como la presencia de grietas en las estructuras, problemas de cimientos e incluso inclinaciones notorias.",
        },
        variant: "rounded",
      },
    ],
  },
};
