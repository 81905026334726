export const ALL_ROUTES = {
  INDEX: "/",
  TRUORA: "/tyc",
  SUCCESS_PROGRESS: "/proceso_exitoso",
  PAYMENT_GATEWAY_WOMPI: "/pasarela_pagos_wompi",
  PAYMENT_WOMPI_SUCCESS: "/pago_wompi_exitoso",
  AFFIDAVIT: "/declaracion-juramentada",
  AFFIDAVIT_MX: "/declaracion-juramentada-mx",
  AFFIDAVIT_LEGAL: "/declaracion-juramentada-legalizacion",
  AFFIDAVIT_LEGAL_MX: "/declaracion-juramentada-legalizacion-mx",
};
